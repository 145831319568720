// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Modulo } from "src/app/contants/modulo";

export const environment = {
  version: "1.0.0",
  production: true,
  apiUrlForSolicitacaoLiberacaoImp: 'https://csn-documental-gateway-api.mgr.services/solicitacao-imp',
  apiUrlForSolicitacaoLiberacaoImpHub: 'https://csn-documental-gateway-api.mgr.services/solicitacao-imp-hub',
  apiUrlForSolicitacaoLiberacaoCabotagem: 'https://csn-documental-gateway-api.mgr.services/solicitacao-cabotagem',
  apiUrlForSolicitacaoLiberacaoCabotagemHub: 'https://csn-documental-gateway-api.mgr.services/solicitacao-cabotagem-hub',
  apiUrlForSolicitacaoLiberacaoExp: 'https://csn-documental-gateway-api.mgr.services/solicitacao-exp',
  apiUrlForCabotagemEmbarque: 'https://csn-documental-gateway-api.mgr.services/cabotagemEmbarque',
  apiUrlForRecepcaoDue: 'https://csn-documental-gateway-api.mgr.services/recepcaoDue',
  apiUrlForLiberacaoDocumental: 'https://csn-documental-gateway-api.mgr.services/liberacaoDocumental',
  apiUrlForLiberacaoDocumentalHub: 'https://csn-documental-gateway-api.mgr.services/liberacaoDocumentalHub',
  apiUrlForRelatorios: 'https://csn-documental-gateway-api.mgr.services/relatorios',
  apiUrlForCadastroDocumento: 'https://csn-documental-gateway-api.mgr.services/cadastroDocumento',
  apiUrlForCadastroDocumentoHub: 'https://csn-documental-gateway-api.mgr.services/cadastroDocumentoHub',
  apiUrlForBloqueioDesbloqueioConteinerEmbarque: 'https://csn-documental-gateway-api.mgr.services/bloqueioDesbloqueioConteinerEmbarque',
  apiUrlForSolicitacaofaturamento: 'https://csn-documental-gateway-api.mgr.services/solicitacaoFaturamento',
  apiUrlForEntrega: 'https://csn-documental-gateway-api.mgr.services/entregaDue',
  apiUrlForMonitoramento: 'https://csn-documental-gateway-api.mgr.services/monitoramento',

  AUTHENTICATION: {
    authority: 'https://csn-documental-gateway-api.mgr.services/servidorIdentidade',
    client_id: Modulo.documental.toLocaleUpperCase(),
    client_secret: 'client_secret_portal_documental',
    redirect_uri: 'https://csn-dev-documental-portal.mgr.services/auth-callback',
    post_logout_redirect_uri: 'http://localhost:4200/home',
    response_type: "code",
    scope: "solicitacao-liberacao-exp-api cabotagem-embarque-api n4-integrator-api pucomex-integrator-api recepcao-due-api rpa-integrator-api solicitacao-liberacao-cabotagem-api solicitacao-liberacao-exp-api documental-api openid profile email roles offline_access",
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    silent_redirect_uri: 'https://csn-dev-documental-portal.mgr.services/silent-refresh.html',
    grant_type:'loginClient',
    timeToRefresh:7200000
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
